<footer class="w-full h-full flex flex-row justify-content-between">
  <section class="flex flex-row align-items-center">
    <nb-icon status="basic" class="cursor-pointer text-2xl" [nbTooltip]="'app.main.about.tooltip' | translate" icon="circle-exclamation"
             (click)="clickLeftIcon.emit()"/>
  </section>
  <section class="flex flex-column align-items-center justify-content-center">
    <div class="font-semibold">
      {{ 'app.footer.copy' | translate }}
      <a [href]="politiesUrl"
         target="_blank" class="no-underline"
         [nbTooltip]="'app.footer.legalNotice.tooltip' | translate"
         [attr.aria-label]="'app.footer.legalNotice.aria' | translate">
        {{ 'app.footer.legalNotice.label' | translate }}
      </a>
    </div>
  </section>
  <section class="flex flex-row align-items-center">
    <a routerLink="main" class="h-full">
      @if (onChangeTheme | async; as data) {
        <img class="company__logo" priority [ngSrc]="logoPath(data.name)"
             [alt]="'app.header.logo.alt' | translate"
             height="127" width="340">
      }

    </a>
  </section>
</footer>
