<mkad-wrapper-dialog
  width="25rem"
  height="33rem"
  iconLeft="gears"
  footerAlign="between"
  [header]="'app.shared.appSettings.header.title'|translate"
  [nbSpinner]="isLoading"
  (clickClose)="handlerClose()">
  @if (formSettings) {
    <form body [formGroup]="formSettings"
          (ngSubmit)="handlerSubmit()">
      <nb-tabset fullWidth class="user__tab" (changeTab)="onChangeTab($event)">
        <nb-tab [tabId]="appSettingsEnum.home">
          <header class="flex align-items-center gap-2 px-3 py-2" *nbTabTitle
                  [nbTooltip]="'app.shared.appSettings.header.tabs.home.label' | translate"
                  [nbTooltipDisabled]="tabSelected === appSettingsEnum.home">
            <nb-icon icon="house"/>
            @if (tabSelected === appSettingsEnum.home) {
              <span>{{ 'app.shared.appSettings.header.tabs.home.label' | translate }}</span>
            }
          </header>
          <div class="flex flex-column flex-wrap gap-3">
            <section>
              <mkad-form-label
                controlId="zoom"
                [text]="'app.shared.appSettings.field.centerLocation.label' | translate"
                [tooltip]="'app.shared.appSettings.field.centerLocation.tooltips' | translate"
                [status]="statusField('zoom')"/>
              <button nbButton outline fullWidth size="small" status="info" type="button" class="mb-2"
                      (click)="openMapDialog()">
                {{ 'app.shared.appSettings.field.centerLocation.button.label'|translate }}
                <nb-icon icon="map-location-dot"/>
              </button>
              <section class="map_view flex flex-column relative h-10rem">
                <mkad-map-basic class="flex w-full h-7rem absolute top-0 right-0" [readonly]="true"
                                [center]="utilsMap.latLng(pointMarked[0], pointMarked[1])"
                                [layers]="[utilsMap.marker(utilsMap.latLng(pointMarked[0], pointMarked[1]))]"
                                [zoom]="formSettings.controls['zoom'].value"/>
                @if (pointMarked) {
                  <div class="grid pt-2 absolute top-0 right-0 mt-10 w-full">
                    <span class="col-3">
                      <mkad-label-icon mkadStatusIcon="info" left="arrows-to-circle"
                                       text="{{'app.shared.appSettings.field.centerLocation.mapView.zoom' |translate}}"/>
                      <strong class="text__truncate" [nbTooltip]="formSettings.controls['zoom'].value">
                        {{ formSettings.controls['zoom'].value }}
                      </strong>
                    </span>
                    <span class="col-4">
                      <mkad-label-icon mkadStatusIcon="info" left="location-dot"
                                       text="{{'app.shared.appSettings.field.centerLocation.mapView.latitude' | translate}}"/>
                      <strong class="text__truncate" [nbTooltip]="pointMarked[0].toString()">
                        {{ pointMarked[0] }}
                      </strong>
                    </span>
                    <span class="col-4">
                      <mkad-label-icon mkadStatusIcon="info" left="location-dot"
                                       text="{{'app.shared.appSettings.field.centerLocation.mapView.longitude'|translate}}"/>
                      <strong class="text__truncate" [nbTooltip]="pointMarked[1].toString()">
                        {{ pointMarked[1] }}
                      </strong>
                    </span>
                  </div>
                }
              </section>
            </section>
            <section class="field__wrap">
              <mkad-form-label
                controlId="refresh_home"
                [text]="'app.shared.appSettings.field.refreshHome.label' | translate"
                [tooltip]="'app.shared.appSettings.field.refreshHome.tooltips' | translate"
                [status]="statusField('refresh_home')"/>
              <nb-select formControlName="refresh_home" fullWidth id="refresh_home" size="small"
                         placeholder="{{'app.shared.appSettings.field.refreshHome.placeholder' | translate}}"
                         nbTooltip="{{'app.shared.appSettings.field.refreshHome.tooltips'|translate}}"
                         [status]="statusField('refresh_home')">
                @for (time of refreshList; track time) {
                  <nb-option [value]="time">{{ time }}</nb-option>
                }
              </nb-select>
            </section>
            <section class="mt-2">
              <div class="w-full flex justify-content-between">
                <mkad-form-label
                  controlId="device_public"
                  [text]="'app.shared.appSettings.field.devicePublic.label' | translate"
                  [tooltip]="'app.shared.appSettings.field.devicePublic.tooltips' | translate"
                  [status]="statusField('device_public')"/>
                <nb-toggle labelPosition="left" class="size__small" status="success"
                           formControlName="device_public" nbTooltipPlacement="right"
                           [nbTooltip]="'app.shared.appSettings.field.devicePublic.tooltips'| translate"/>
              </div>
            </section>
          </div>
        </nb-tab>
        <nb-tab [tabId]="appSettingsEnum.report">
          <header class="flex align-items-center gap-2 px-3 py-2" *nbTabTitle
                  [nbTooltip]="'app.shared.appSettings.header.tabs.report.label' | translate"
                  [nbTooltipDisabled]="tabSelected === appSettingsEnum.report">
            <nb-icon icon="files"/>
            @if (tabSelected === appSettingsEnum.report) {
              <span>{{ 'app.shared.appSettings.header.tabs.report.label' | translate }}</span>
            }
          </header>
          <div class="flex flex-column flex-wrap gap-3">
            <section class="field__wrap">
              <mkad-form-label
                controlId="decimal"
                [text]="'app.shared.appSettings.field.decimal.label' | translate"
                [tooltip]="'app.shared.appSettings.field.decimal.tooltips' | translate"
                [status]="statusField('decimal')"/>
              <nb-select formControlName="decimal" fullWidth id="decimal" size="small"
                         placeholder="{{'app.shared.appSettings.field.decimal.placeholder' | translate}}"
                         nbTooltip="{{'app.shared.appSettings.field.decimal.tooltips'|translate}}"
                         [status]="statusField('decimal')">
                @for (decimal of decimalList; track decimal) {
                  <nb-option [value]="decimal">{{ decimal }}</nb-option>
                }
              </nb-select>
            </section>
            <section class="field__wrap">
              <mkad-form-label
                controlId="export"
                [text]="'app.shared.appSettings.field.export.label' | translate"
                [tooltip]="'app.shared.appSettings.field.export.tooltips' | translate"
                [status]="statusField('export')"/>
              <nb-radio-group formControlName="export" class="block" status="info" id="export">
                @for (option of exportTypeList; track option.id) {
                  <nb-radio
                    class="inline-flex"
                    [value]="option.id"
                    [nbTooltip]="option.name">
                    {{ option.name }}
                  </nb-radio>
                }
              </nb-radio-group>
            </section>
            <section class="field__wrap">
              <mkad-form-label
                controlId="data_null"
                [text]="'app.shared.appSettings.field.nullManage.label' | translate"
                [tooltip]="'app.shared.appSettings.field.nullManage.tooltips' | translate"
                [status]="statusField('data_null')"/>
              <nb-select formControlName="data_null" fullWidth id="data_null" size="small"
                         placeholder="{{'app.shared.appSettings.field.nullManage.placeholder' | translate}}"
                         nbTooltip="{{'app.shared.appSettings.field.nullManage.tooltips'|translate}}"
                         [status]="statusField('data_null')">
                @for (item of nullManageList; track item.id) {
                  <nb-option [value]="item.id">{{ item.name }}</nb-option>
                }
              </nb-select>
            </section>
          </div>
        </nb-tab>
        <nb-tab [tabId]="appSettingsEnum.dashboard">
          <header class="flex align-items-center gap-2 px-3 py-2" *nbTabTitle
                  [nbTooltip]="'app.shared.appSettings.header.tabs.dashboard.label' | translate"
                  [nbTooltipDisabled]="tabSelected === appSettingsEnum.dashboard">
            <nb-icon icon="chart-scatter"/>
            @if (tabSelected === appSettingsEnum.dashboard) {
              <span>{{ 'app.shared.appSettings.header.tabs.dashboard.label' | translate }}</span>
            }
          </header>
          <div class="flex flex-column flex-wrap gap-3">
            <section class="field__wrap">
              <mkad-form-label
                controlId="refresh_Dashboard"
                [text]="'app.shared.appSettings.field.refreshDashboard.label' | translate"
                [tooltip]="'app.shared.appSettings.field.refreshDashboard.tooltips' | translate"
                [status]="statusField('refresh_Dashboard')"/>
              <nb-select formControlName="refresh_Dashboard" fullWidth id="refresh_Dashboard" size="small"
                         [placeholder]="'app.shared.appSettings.field.refreshDashboard.placeholder' | translate"
                         [nbTooltip]="'app.shared.appSettings.field.refreshDashboard.tooltips'|translate"
                         [status]="statusField('refresh_Dashboard')">
                @for (time of refreshList; track time) {
                  <nb-option [value]="time">{{ time }}</nb-option>
                }
              </nb-select>
            </section>
          </div>
        </nb-tab>
      </nb-tabset>
    </form>
  }
  <ng-container footer>
    <button nbButton outline size="small" status="danger" type="button" (click)="handlerClose()"
            [nbTooltip]=" 'app.shared.appSettings.footer.button.cancel.tooltip'|translate">
      {{ 'app.shared.appSettings.footer.button.cancel.label'|translate }}
    </button>
    <button nbButton outline size="small" status="success" type="button" (click)="handlerSubmit()"
            [nbTooltip]=" 'app.shared.appSettings.footer.button.save.tooltip'|translate">
      {{ 'app.shared.appSettings.footer.button.save.label'|translate }}
    </button>
  </ng-container>
</mkad-wrapper-dialog>
