import {AfterViewInit, Component, Inject, OnInit, signal, ViewChild, ViewContainerRef} from '@angular/core';
import {Router, RouterOutlet} from '@angular/router';
import {
  NbIconLibraries, NbLayoutColumnComponent,
  NbLayoutModule,
  NbMenuModule,
  NbSidebarModule,
  NbSpinnerModule,
} from '@nebular/theme';
import {TranslateService} from '@ngx-translate/core';
import {Meta} from '@angular/platform-browser';
import {
  catchError,
  EMPTY,
  filter,
  finalize, Observable,
  of,
  switchMap,
  tap,
} from 'rxjs';
import {icon, Marker} from 'leaflet';
import {ZbChangePasswordComponent, ZbLoginComponent} from '@zibanu/ui';
import {UserLevelEnum, ZbAuthManagerService} from '@zibanu/auth';
import {ZbSecureStorageService} from '@zibanu/lib';
import {AboutUsService, MkadMenuManagerService} from '@shared/services';
import {
  AppSettingsComponent, FrameFooterComponent,
  FrameHeaderComponent,
  MkadAboutUsComponent,
  UserProfileComponent,
} from '@shared/components';
import {
  DEFAULT_MAP_LATITUDE, DEFAULT_MAP_LONGITUDE,
  EXTRA_ICON_CONFIG,
  MENU_MAIN_TAG,
  SIDEBAR_MAIN_ID,
} from '@shared/constants';
import {LanguageAvailableType} from '@shared/types/language-available.type';
import {AllowedLanguagesEnum} from '@shared/enums';
import {TERMS_CONDITIONS_CONST} from '@shared/utils';
import {MkadBaseComponent} from '@shared/components/base/mkad-base.component';
import {MkadUserCustomersService} from '@shared/services/settings/mkad-user-customers.service';
import {AsyncPipe} from '@angular/common';
import {MkadScrollControlService} from '@shared/services/main/mkad-scroll-control.service';
import {UserCustomerDataInterface} from '@shared/interface/settings';
import {CoreMkadStatusEnum} from '@shared/interface/settings/device/customer-device/customer-device.interface';
import {CoreSessionStorageService} from './auth/service/core-session-storage.service';
import {PartialUserInterface} from '@shared/interface/common/partial-user.interface';
import {DEFAULT_APP_SETTINGS_CONFIG} from './auth/tokens/default-app-settings-config.token';
import { AppSettingsRequestInterface } from '@shared/interface/main/app-settings-request.interface';

/**
 * App component
 * @selector mkad-app
 * @extends MkadBaseComponent
 * @standalone False
 */
@Component({
  selector: 'mkad-app',
  templateUrl: './app.component.html',
  standalone: true,
  imports: [NbLayoutModule, NbSidebarModule, NbSpinnerModule, NbMenuModule, FrameHeaderComponent, FrameFooterComponent, AsyncPipe, RouterOutlet],
  providers: [AboutUsService],
})
export class AppComponent extends MkadBaseComponent implements OnInit, AfterViewInit {
  isSessionActive = false;
  userLevel = UserLevelEnum.Guest;
  politiesUrl = TERMS_CONDITIONS_CONST;
  mainMenu$$ = this.menuService.onChangeMainMenu();
  sidebarMainTag = SIDEBAR_MAIN_ID;
  menuMainTag = MENU_MAIN_TAG;
  private isFirstTokenChange = signal(true);
  @ViewChild(NbLayoutColumnComponent, {read: ViewContainerRef}) container!: ViewContainerRef;  //this is always undefined
  override toTranslate = {
    'zibanu.ui.login.message.success': '',
    'zibanu.ui.logout.message.success': '',
  };


  /**
   * @description
   * Constructor class
   * @param iconLibrary NbIconLibraries dependency injection
   * @param translate TranslateService dependency injection
   * @param meta Meta dependency injection
   * @param authService ZbAuthManagerService dependency injection
   * @param storage SecureStorageService dependency injection
   * @param menuService MkadMenuManagerService dependency injection
   * @param userCustomer MkadUserCustomerService dependency injection
   * @param route Router dependency injection
   * @param scrollControl MkadScrollControlService dependency injection
   * @param sessionStorage CoreSessionStorageService dependency injection
   * @param defaultAppSettings CoreSessionStorageService dependency injection
   */
  constructor(override translate: TranslateService,
              private readonly iconLibrary: NbIconLibraries,
              private readonly meta: Meta,
              private readonly authService: ZbAuthManagerService,
              private readonly storage: ZbSecureStorageService,
              private readonly menuService: MkadMenuManagerService,
              private readonly userCustomer: MkadUserCustomersService,
              private route: Router,
              private scrollControl: MkadScrollControlService,
              private readonly sessionStorage: CoreSessionStorageService,
              @Inject(DEFAULT_APP_SETTINGS_CONFIG) private readonly defaultAppSettings: AppSettingsRequestInterface,
  ) {
    super(translate);
    this.setupIconConfig();
    this.initDefaultLang();
    this.handlerTokenChange();
    this.meta.updateTag({name: 'theme-color', content: 'rgb(206,225,251)'});
    this.handlerMenuEvents();
    this.loadMapSource();
    this.authService.isAuthenticated().subscribe({
      next: (authenticated) => {
        if (!authenticated) this.handleInactiveSession();
      },
    });
  }


  get userCustomerData(): UserCustomerDataInterface | null {
    return this.preferences.userCustomer();
  }

  ngAfterViewInit(): void {
    if (this.container) {
      this.scrollControl.layout = this.container;
    }
  }

  /**
   * @description
   * Method that listen the click on nebular menu
   * @private
   */
  private handlerMenuEvents() {
    this.menuService.onClickLogout().pipe(
      switchMap(() => {
        this.isLoading = true;
        return this.authService.doLogout();
      }),
      tap(() => {
        this.showSuccess(this.getTranslate('zibanu.ui.logout.message.success'));
        this.isFirstTokenChange.set(true);
        this.preferences.userCustomer.set(null);
        this.preferences.appSettings = undefined;
        this.preferences.changeToDefaultConfig();
        this.route.navigate(['home']).then();
        this.sessionStorage.removeAll();
        this.isLoading = false;
      }),
      catchError(() => {
        return EMPTY;
      }),
    ).subscribe();

    this.menuService.onClickUserSetting().subscribe(() => this.openUserProfileDialog());
    this.menuService.onClickAppSetting().subscribe(() => this.openAppSettings());
    this.menuService.onClickAboutUs().subscribe(() => this.openAboutUsDialog());
    this.menuService.onClickChangePassword().subscribe(() => this.openChangePasswordDialog());
  }

  /**
   * @description
   * Method that open the user preferences dialog
   * @private
   */
  private openAppSettings(): void {
    if (!this.preferences.appSettings) {
      this.saveAppSettings(() => {
        this.dialog.open(AppSettingsComponent).onClose.subscribe({
          next: (response) => {
            this.closeSettingDialog(response);
            this.handleLocationConfig(response);
          },
        });
      });
    } else {
      this.dialog.open(AppSettingsComponent).onClose.subscribe({
        next: (response) => {
          this.closeSettingDialog(response);
          this.handleLocationConfig(response);
        },
      });
    }
  }

  private saveAppSettings(callback: () => void): void {
    this.preferences.updateAppSetting(this.defaultAppSettings).subscribe({
      next: (updated) => {
        if (updated === null) {
          callback();
        }
      }
    });
  }

  private handleLocationConfig(response: { save: boolean }): void {
    if (response && !response.save && this.preferences.appSettings === undefined && navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        ({coords}) => {
          this.preferences.centerMap.set([coords.latitude, coords.longitude]);
        },
        (error) => {
          console.log(error);
          this.preferences.centerMap.set([DEFAULT_MAP_LATITUDE, DEFAULT_MAP_LONGITUDE]);
        },
      );
    }
  }

  /**
   * @description
   * Method that open the user preferences dialog
   * @private
   */
  private openUserProfileDialog() {
    this.dialog.open(UserProfileComponent).onClose.subscribe({
      next: (response) => this.closeSettingDialog(response),
    });
  }

  /**
   * @description
   * Method that open the about us dialog
   * @private
   */
  openAboutUsDialog() {
    this.dialog.open(MkadAboutUsComponent)
      .onClose
      .subscribe({ next: () => this.sessionStorage.setAboutUs()});
  }

  /**
   * @description
   * Method that opens the mode if you are logged in or are a guest
   * @private
   */
  private handleOpenAboutUs(): void {
    if (this.isSessionActive) {
      if (!this.sessionStorage.getAboutUs) {
        this.openAboutUsDialog();
        this.sessionStorage.setAboutUs();
      }
    } else {
      this.openAboutUsDialog();
    }
  }

  /**
   * @description
   * Method that updates the token
   * @param response
   */
  private closeSettingDialog(response: { save: boolean }): void {
    if (response && response.save) {
      this.isLoading = true;
      this.refreshUserToken();
    }
  }

  /**
   * @description
   * Method that updates the user token
   * @private
   */
  private refreshUserToken(): void {
    this.authService.getToken()
      .pipe(
        filter(nbToken => nbToken.isValid()),
        switchMap(nbToken => this.authService.refreshToken({token: nbToken.getValue()})),
        tap(() => this.isLoading = false),
        catchError((error) => {
          this.handleErrorResponse(error);
          return of(error);
        }),
      )
      .subscribe();
  }

  /**
   * @description
   * Method that open the change password dialog
   * @private
   */
  private openChangePasswordDialog() {
    this.dialog.open(ZbChangePasswordComponent);
  }

  /**
   * @description
   * Method that responds to the OnInit lifecycle
   */
  override ngOnInit() {
    super.ngOnInit();
    this.handleOpenAboutUs();
  }

  private handleInvalidSession(): void {
    this.userLevel = UserLevelEnum.Guest;
    this.menuService.reloadMainMenu(this.userLevel);
    this.isLoading = false;
  }

  private handleValidSession(user: PartialUserInterface): void {
    this.userLevel = user.level ?? UserLevelEnum.Guest;
    this.preferences.lastLogin = user.last_login ?? undefined;
    this.preferences.username = user.username ?? undefined;
    this.preferences.level = this.userLevel;
  }

  private handleCustomerDisclaimer(data: UserCustomerDataInterface): void {
    if (data.contract_status > CoreMkadStatusEnum.success && !this.sessionStorage.getDisclaimer) {
      this.alert(data.contract_status_message)?.then(() => {
        this.sessionStorage.setDisclaimer();
      });
    }
  }

  private handlerUserProfile(): Observable<UserCustomerDataInterface> {
    return this.preferences.getProfileData().pipe(
      tap(userProfileResponse => {
        if (userProfileResponse) {
          const {email, first_name, last_name, profile} = userProfileResponse;
          this.preferences.userSettings = {
            email,
            first_name,
            last_name,
            ...profile,
          };
          console.log(profile);
          if (this.isFirstTokenChange() && profile && profile.app_settings === null) {
            this.isFirstTokenChange.set(false);
            this.openAppSettings();
          }
          this.preferences.changeUserConfig();
          this.menuService.reloadMainMenu(this.userLevel);
        } else {
          this.preferences.changeToDefaultConfig();
        }
      }),
      switchMap(() => {
        return this.userCustomer.getCustomer();
      }),
      tap(response => {
        console.log('Response from userCustomer:', response);
        this.preferences.userCustomer.set(response);
        if (response) this.handleCustomerDisclaimer(response);
      }),
      finalize(() => {
        this.isLoading = false;
      }),
    );
  }

  /**
   * @description
   * Method that subscribes to token exchange
   * @private
   */
  private handlerTokenChange(): void {
    this.authService.onChangeToken()
      .pipe(
        switchMap(tokenResponse => {
          console.log(tokenResponse);
          this.isSessionActive = tokenResponse.isValid();
          if (!this.isSessionActive) {
            this.handleInvalidSession();
            return of({avatar: undefined});
          }

          const {user} = tokenResponse.getPayload();
          this.handleValidSession(user);
          return this.handlerUserProfile();
        }),
        catchError(errorResponse => {
          this.isLoading = false;
          return of(errorResponse);
        }),
      )
      .subscribe();
  }

  /**
   * @description
   * Method handling the inactive session
   * @private
   */
  private handleInactiveSession(): void {
    const {hasSessionStored} = this.storage.getUserCredentials();
    if (hasSessionStored) {
      this.authService.doStoredLogin().subscribe({
        next: (storeResponse) => {
          console.log('login desde el storage', storeResponse);
          this.isLoading = false;
        },
        error: (error) => this.handleErrorResponse(error),
      });
    }
  }

  /**
   * @description
   * Method that init the library config
   */
  setupIconConfig(): void {
    this.iconLibrary.registerFontPack('fak', {packClass: 'fak', iconClassPrefix: 'fa'});
    this.iconLibrary.registerFontPack('far', {packClass: 'far', iconClassPrefix: 'fa'});
    this.iconLibrary.registerFontPack('fab', {packClass: 'fab', iconClassPrefix: 'fa'});
    this.iconLibrary.setDefaultPack('far');
  }

  /**
   * @description
   * Method that init the ngx-translate config
   * @private
   */
  private initDefaultLang(): void {
    const browserLangList: string[] = ['es', 'en'];
    this.translate.addLangs(browserLangList);
    this.translate.setDefaultLang(AllowedLanguagesEnum.en);
    this.translate.use(this.translate.getBrowserLang() ?? AllowedLanguagesEnum.es);
  }

  /**
   * @description
   * Method that load the local path of icons
   * @private
   */
  private loadMapSource() {
    Marker.prototype.options.icon = icon({...EXTRA_ICON_CONFIG});
  }

  /**
   * @description
   * Method that responds to the current language of the application
   */
  getCurrentLang(): LanguageAvailableType {
    return this.translate.currentLang as LanguageAvailableType;
  }

  /**
   * @description
   * Method that responds to clicking on the header avatar
   */
  onClickAvatar(): void {
    this.dialog.open(ZbLoginComponent);
  }

}
