/*
 * Developed by CQ Inversiones SAS. Copyright ©. 2019 - 2024. All rights reserved.
 * Desarrollado por CQ Inversiones SAS. Copyright ©. 2019 - 2024. Todos los derechos reservados.
 */

import {Injectable} from '@angular/core';
import {MkadBaseService, URLS_SERVICE} from '@shared/services';
import {map, Observable} from 'rxjs';
import {
  TriggerEnumsInterface,
  TriggersFormInterface,
  TriggersListInterface,
  DeviceSummaryListInterface,
} from '@shared/interface/settings/device/triggers/triggers.interface';
import {HttpResponse} from '@angular/common/http';
import {HTTP_CODE_SUCCESS} from '@shared/constants';
import {MkadGroupParamInterface} from '@shared/interface';

@Injectable()
export class MkadTriggersServices extends MkadBaseService{
  private readonly triggers = URLS_SERVICE.devices.triggers;
  private readonly customer = URLS_SERVICE.meerkad.customer;
  private readonly device = URLS_SERVICE.meerkad.device;
  readonly enumTrigger = URLS_SERVICE.devices.enumTrigger;

  /**
   * @description
   * Method that queries the trigger and rule enums listing
   */
  listEnums(): Observable<TriggerEnumsInterface> {
    return this.http.post<TriggerEnumsInterface>(this.baseCore + this.enumTrigger.base + this.enumTrigger.data);
  }

  /**
   * @description
   * Method that queries the trigger summary listing
   */
  summaryVarLimit(param: MkadGroupParamInterface): Observable<DeviceSummaryListInterface> {
    return this.http.post<DeviceSummaryListInterface>(this.customer.base + this.device.base + this.device.varLimit, param);
  }

  /**
   * @description
   * Method that queries the nested list of triggers
   */
  list(): Observable<TriggersListInterface[]> {
    return this.http.post<TriggersListInterface[]>(this.customer.base + this.triggers.base + this.triggers.list);
  }

  /**
   * @description
   * Method that sends a request to create a trigger
   * @param payload payload for the endpoint
   */
  create(payload: TriggersFormInterface): Observable<TriggersFormInterface> {
    return this.http.post<TriggersFormInterface>(this.customer.base + this.triggers.base + this.triggers.create, payload);
  }

  /**
   * @description
   * Method that sends a request to update a trigger
   * @param payload payload for the endpoint
   */
  update(payload: TriggersFormInterface): Observable<TriggersFormInterface> {
    return this.http.post<TriggersFormInterface>(this.customer.base + this.triggers.base + this.triggers.update, payload);
  }

  /**
   * @description
   * Method that sends a request to obtain the id of a trigger
   * @param param id of the trigger
   */
  get(param: number): Observable<TriggersFormInterface> {
    return this.http.post<TriggersFormInterface>(this.customer.base + this.triggers.base + this.triggers.get, {id: param});
  }

  /**
   * @description
   * Method that delete a specific trigger
   * @param param id of the trigger
   */
  delete(param: number): Observable<boolean> {
    return this.http.post<HttpResponse<boolean>>(this.customer.base + this.triggers.base + this.triggers.delete, {id: param}).pipe(
      map((prevResponse) => {
          if (!prevResponse) return true;
          return prevResponse.status === HTTP_CODE_SUCCESS;
        },
      ));
  }
}
