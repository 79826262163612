import {Component, OnDestroy, OnInit, inject, DestroyRef} from '@angular/core';
import {
  NbActionsModule,
  NbButtonModule,
  NbCardModule,
  NbDialogRef, NbFormFieldModule,
  NbIconModule, NbInputModule,
  NbRadioModule,
  NbSelectModule,
  NbSpinnerModule, NbTabsetModule, NbToggleModule,
  NbTooltipModule,
} from '@nebular/theme';
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import {latLng, marker} from 'leaflet';
import {
  DECIMAL_OPTIONS, DEFAULT_MAP_LATITUDE, DEFAULT_MAP_LONGITUDE,
  EXPORT_TYPE_OPTIONS,
  MANAGE_DATA_NULL_OPTIONS, REFRESH_OPTIONS,
} from '@shared/constants';
import {
  AppSettingsRequestInterface,
  MkadResumeDataListInterface,
} from '@shared/interface';
import {
  MapMarkersComponent,
  MkadWrapperDialogFormComponent,
  MkadLabelIconComponent,
  MkadMapBasicComponent,
  MkadFormLabelComponent,
} from '../../global';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {MkadBaseComponent} from '@shared/components';
import {DEFAULT_APP_SETTINGS_CONFIG} from '../../../../auth/tokens/default-app-settings-config.token';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';

export enum AppSettingsEnum {
  'home' = 'home',
  'report' = 'report',
  'dashboard' = 'dashboard',
}

/**
 * Dialog component to update the app setting of the user
 * @selector mkad-app-settings
 * @standalone True
 * @extends MkadBaseComponent
 */
@Component({
  selector: 'mkad-app-settings',
  templateUrl: './app-settings.component.html',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    TranslateModule,
    MkadLabelIconComponent,
    MkadMapBasicComponent,
    MkadWrapperDialogFormComponent,
    NbCardModule,
    NbActionsModule,
    NbButtonModule,
    NbIconModule,
    NbSelectModule,
    NbRadioModule,
    NbSpinnerModule,
    NbTooltipModule,
    MkadFormLabelComponent,
    NbTabsetModule,
    NbFormFieldModule,
    NbInputModule,
    NbToggleModule,
  ],
  providers: [],
})
export class AppSettingsComponent extends MkadBaseComponent<AppSettingsComponent> implements OnInit, OnDestroy {
  override toTranslate = {
    'app.shared.appSettings.message.success': '',
  };
  protected readonly appSettingsEnum = AppSettingsEnum;
  protected tabSelected: AppSettingsEnum = AppSettingsEnum.home;
  utilsMap = {latLng, marker};
  formSettings!: FormGroup;
  decimalList: number[] = DECIMAL_OPTIONS;
  refreshList: number[] = REFRESH_OPTIONS;
  nullManageList: MkadResumeDataListInterface<number>[] = MANAGE_DATA_NULL_OPTIONS;
  exportTypeList: MkadResumeDataListInterface<number>[] = EXPORT_TYPE_OPTIONS;
  private _pointMarked: [number, number] = [DEFAULT_MAP_LATITUDE, DEFAULT_MAP_LONGITUDE];
  private readonly defaultAppSettings = inject(DEFAULT_APP_SETTINGS_CONFIG);
  private readonly destroy = inject(DestroyRef);

  get pointMarked(): [number, number] {
    return this._pointMarked;
  }

  set pointMarked(value: [number, number]) {
    this._pointMarked = value;
  }

  /**
   * @description
   * Constructor class
   * @param translate TranslateService dependency injection
   * @param dialogRef NbDialogRef dependency injection
   * @param builder FormBuilder dependency injection
   */
  constructor(
    protected override translate: TranslateService,
    protected override readonly dialogRef: NbDialogRef<AppSettingsComponent>,
    private readonly builder: FormBuilder) {
    super(translate, dialogRef);
    this.translate.onLangChange
      .pipe(takeUntilDestroyed(this.destroy))
      .subscribe({next: () => this.translateNullManageList()});
  }

  /**
   * @description
   * Method implemented by the OnInit lifecycle
   * @override
   */
  override ngOnInit() {
    super.ngOnInit();
    this.translateNullManageList();
    this.initForm();
  }


  /**
   * @description
   * Method that translates null manage list
   * @override
   */
  private translateNullManageList() {
    const manageTranslate = structuredClone([...MANAGE_DATA_NULL_OPTIONS]);
    this.nullManageList = this.getTranslateMap(manageTranslate, ['name']);
  }

  /**
   * @description
   * Method that initializes the form
   */
  private initForm() {
    let data = this.preferences.appSettings;
    if (!data) data = {...this.defaultAppSettings};
    this.formSettings = this.builder.group({
      decimal: [data.decimals, [Validators.required]],
      export: [data.format_export],
      zoom: [data.zoom_map],
      center_lat: [data.center_map[0]],
      center_lng: [data.center_map[1]],
      data_null: [data.manage_null, [Validators.required]],
      refresh_Dashboard: [data.dashboard_refresh_time, [Validators.required]],
      refresh_home: [data.home_refresh_time, [Validators.required]],
      device_public: [data.view_public_devices],
    });
    this.pointMarked = [this.formSettings.controls['center_lat'].value, this.formSettings.controls['center_lng'].value];
    this.isLoading = false;
  }

  /**
   * @description
   * Method that implements the OnDestroy life cycle
   */
  override ngOnDestroy() {
    super.ngOnDestroy();
  }

  /**
   * @description
   * Method that close the dialog component
   */
  handlerClose() {
    super.onCloseDialog({save: false});
  }

  /**
   * @description
   * Method that organizes the form data to update it
   */
  handlerSubmit() {
    if (this.formSettings.valid) {
      this.isLoading = true;
      const appSettingToUpdate: AppSettingsRequestInterface = {
        center_map: [this.formSettings.controls['center_lat'].value, this.formSettings.controls['center_lng'].value],
        decimals: this.formSettings.controls['decimal'].value,
        format_export: this.formSettings.controls['export'].value,
        manage_null: this.formSettings.controls['data_null'].value,
        zoom_map: this.formSettings.controls['zoom'].value,
        dashboard_refresh_time: this.formSettings.controls['refresh_Dashboard'].value,
        home_refresh_time: this.formSettings.controls['refresh_home'].value,
        view_public_devices: this.formSettings.controls['device_public'].value,
      };
      this.preferences.updateAppSetting(appSettingToUpdate).subscribe({
        next: (response) => {
          if (response === null) {
            this.showSuccess(this.getTranslate('app.shared.appSettings.message.success'));
            super.onCloseDialog({save: true, data: response});
          }
          this.isLoading = false;
        },
        error: (errorResponse) => this.handleErrorResponse(errorResponse),
      });
    }
    this.formSettings.markAllAsTouched();
  }

  /**
   * @description
   * Method that opens the map modal
   */
  openMapDialog(): void {
    this.dialog.open(MapMarkersComponent, {
      context: {
        dialogContext: {
          center: latLng(this.formSettings.controls['center_lat'].value, this.formSettings.controls['center_lng'].value),
          zoom: this.formSettings.controls['zoom'].value,
        },
      },
    }).onClose.subscribe({
      next: (response) => {
        console.log(response);
        if (response) {
          this.formSettings.controls['zoom'].setValue(response.zoom);
          this.formSettings.controls['center_lat'].setValue(response.center['lat']);
          this.formSettings.controls['center_lng'].setValue(response.center['lng']);
          this.pointMarked = [response.center['lat'], response.center['lng']];
        }
      },
    });
  }

  /**
   * @description
   * Method that returns if a specific field has errors.
   * @param control field form control
   * @private
   */
  private validStatusField(control: string) {
    return this.hasErrorField(control) ? 'danger' : 'success';
  }

  /**
   * @description
   * Method that returns if a specific field has errors.
   * @param control field form control
   * @private
   */
  private hasErrorField(control: string): boolean {
    return !!this.formSettings?.controls[control].errors;
  }


  /**
   * @description
   * Method that returns the status of the input
   * @param control
   */
  statusField(control: string) {
    return this.validStatusField(control);
  }

  /**
   * @description
   * Method used to change tab
   * @param tabId
   */
  onChangeTab({tabId}: { tabId: AppSettingsEnum }) {
    if (tabId === AppSettingsEnum.home) this.tabSelected = AppSettingsEnum.home;
    if (tabId === AppSettingsEnum.report) this.tabSelected = AppSettingsEnum.report;
    if (tabId === AppSettingsEnum.dashboard) this.tabSelected = AppSettingsEnum.dashboard;
  }
}
