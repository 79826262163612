import {Component, EventEmitter, inject, OnInit, Output} from '@angular/core';
import {NbMenuModule, NbTagModule, NbTooltipModule} from '@nebular/theme';
import {AsyncPipe, DatePipe} from '@angular/common';
import {MkadMenuManagerService, PreferencesService} from '@shared/services';
import {MENU_OVERLAY_TAG} from '@shared/constants';
import {UserLevelEnum} from '@zibanu/auth';
import {TranslateModule, TranslatePipe} from '@ngx-translate/core';

export const LEVEL_USER: Record<UserLevelEnum, string> = {
  [UserLevelEnum.SuperUser]: 'app.shared.session.level.superUser',
  [UserLevelEnum.Staff]: 'app.shared.session.level.staff',
  [UserLevelEnum.SuperAdmin]: 'app.shared.session.level.superAdmin',
  [UserLevelEnum.Admin]: 'app.shared.session.level.admin',
  [UserLevelEnum.Supervisor]: 'app.shared.session.level.supervisor',
  [UserLevelEnum.Operator]: 'app.shared.session.level.operator',
  [UserLevelEnum.Guest]: 'app.shared.session.level.guest',
};

export const LEVEL_CLASS: Record<UserLevelEnum, string> = {
  [UserLevelEnum.SuperUser]: 'super-user',
  [UserLevelEnum.Staff]: 'staff',
  [UserLevelEnum.SuperAdmin]: 'super-admin',
  [UserLevelEnum.Admin]: 'admin',
  [UserLevelEnum.Supervisor]: 'supervisor',
  [UserLevelEnum.Operator]: 'ope',
  [UserLevelEnum.Guest]: 'guest',
};

@Component({
  selector: 'mkad-user-overlay',
  standalone: true,
  imports: [NbMenuModule, AsyncPipe, DatePipe, NbTagModule, TranslateModule, NbTooltipModule],
  template: `
    <div class="user__overlay flex flex-column align-items-center w-16rem h-auto py-3 gap-2">
      @if (userAvatar) {
        <img [src]="userAvatar" class="border-circle" alt="user-logo-image" width="150" height="150">
      }
      <nb-tag [class]="classLevel[level]" status="control" appearance="outline" [text]="(userLevel[level]) | translate" class="mt-2"/>
      <small class="m-0" [nbTooltip]="'app.main.overlay.lastLogin' | translate" nbTooltipPlacement="start">
        {{ lastLogin | date:  'YYYY-MM-dd h:mm a' }}
      </small>
    </div>
    @if (overlayMenu$$ | async; as menu) {
      <nb-menu [tag]="userOverlayTag" [items]="menu" (click)="onClickMenu()"/>
    }
  `,
})
export class UserOverlayComponent {
  @Output() clickOverlay = new EventEmitter();
  private readonly mkadMenu = inject(MkadMenuManagerService);
  private readonly preferences = inject(PreferencesService);
  readonly userOverlayTag = MENU_OVERLAY_TAG;
  readonly userLevel = LEVEL_USER;
  readonly classLevel = LEVEL_CLASS;
  readonly overlayMenu$$ = this.mkadMenu.onChangeOverlayMenu();

  /**
   * @description
   * Method that response to click on menu
   */
  onClickMenu() {
    this.clickOverlay.emit();
  }

  /**
   * @description
   * Method that return the user avatar
   */
  get userAvatar(): string | undefined {
    return this.preferences.avatar;
  }

  /**
   * @description
   * Method that return the last login
   */
  get lastLogin(): string {
    return this.preferences.lastLogin ?? '';
  }

  /**
   * @description
   * Method that return the level
   */
  get level(): UserLevelEnum {
    return this.preferences.level ?? '';
  }
}
