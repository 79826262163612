<section class="flex gap-2 my-2 md:px-7 align-items-center">
  <div class="text-lg font-bold">{{ headerTitle }}</div>
  <div class="border__content flex-1 hidden md:block  "></div>
</section>
<section class="container__a">
  <div class="container container__b">
    <div>
      <div #carouselWrap id="carouselWrap" class="carousel__panel py-2">
        @for (card of cardList; track card; let i = $index) {
          <nb-card mkadCheckLevel [elementType]="levelType.div" [minLevel]="card.data.level"
                   class="card__base cursor-pointer gap-3 p-3 m-0 align-items-center border-round-2xl "
                   (mkadClick)="onClickCard(card.link ?? '')" [nbSpinner]="isLoading">
            <div class="flex">
              @if (card.icon) {
                <div class="col-fixed flex justify-content-center align-content-center">
                  <nb-icon [icon]="parseIcon(card.icon)"
                           class="btn__icon border-circle w-4rem h-4rem text-4xl flex align-items-center justify-content-center"
                  />
                </div>
              }
              <div class="col flex flex-column gap-2 justify-content-center w-10rem">
                <h5 class="text-base line-height-1 m-0 font-semibold">{{ card.title }}</h5>
                <span class="text-sm line-height-1 font-light">{{ card.data.description }}</span>
              </div>
            </div>
          </nb-card>
        }
      </div>
    </div>
    @if (showButtons) {
      <div class="btn__arrow">
        <div class="left absolute flex h-8rem">
          <button nbButton ghost type="button" class="h-8rem" (click)="clickScrollLeft()">
            <nb-icon icon="chevron-left"/>
          </button>
        </div>
        <div class="right absolute flex h-8rem">
          <button nbButton ghost type="button" class="h-8rem" (click)="clickScrollRight()">
            <nb-icon icon="chevron-right"/>
          </button>
        </div>
      </div>
    }
  </div>
</section>
