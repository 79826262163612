import {Component, EventEmitter, inject, input, Input, OnInit, Output} from '@angular/core';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {NbCardModule, NbIconModule, NbTooltipModule, NbUserModule} from '@nebular/theme';
import {NgOptimizedImage} from '@angular/common';
import {CdkConnectedOverlay, CdkOverlayOrigin, OverlayModule} from '@angular/cdk/overlay';
import {CdkTrapFocus} from '@angular/cdk/a11y';
import {MkadStatusDeviceComponent} from '../status-device/status-device.component';
import {UserOverlayComponent} from '../user-overlay/user-overlay.component';
import {SharedModule} from '@shared/shared.module';
import {UserCustomerDataInterface} from '@shared/interface';
import {MkadBaseComponent} from '@shared/components';
import {DEFAULT_OWNER_URL} from '../../../../auth/tokens/default-owner-url';
import {CoreMkadStatusEnum} from '@shared/interface/settings/device/customer-device/customer-device.interface';

@Component({
  selector: 'mkad-frame-header',
  templateUrl: './frame-header.component.html',
  standalone: true,
  imports: [
    TranslateModule,
    NgOptimizedImage,
    CdkOverlayOrigin,
    CdkConnectedOverlay,
    CdkTrapFocus,
    OverlayModule,
    NbIconModule,
    NbTooltipModule,
    NbCardModule,
    NbUserModule,
    UserOverlayComponent,
    MkadStatusDeviceComponent,
    SharedModule,
  ],
})
export class FrameHeaderComponent extends MkadBaseComponent implements OnInit {
  @Input({required: true}) isActive = false;
  @Output() clickToggleIcon = new EventEmitter<null>();
  @Output() clickAvatar = new EventEmitter<null>();
  readonly customerData = input.required<UserCustomerDataInterface | null>();
  private readonly ownerUrl = inject(DEFAULT_OWNER_URL);
  showOverlay = false;
  private readonly warningsClassControl: Record<CoreMkadStatusEnum, string> = {
    [CoreMkadStatusEnum.success]: '',
    [CoreMkadStatusEnum.alert]: 'contract_alert',
    [CoreMkadStatusEnum.warning]: 'contract_warning',
    [CoreMkadStatusEnum.error]: 'contract_error',
  };
  /**
   * @description
   * Constructor class
   * @param translate TranslateService dependency injection
   */
  constructor(override translate: TranslateService) {
    super(translate);
  }

  /**
   * @description
   * Method that returns the url of the owner
   */
  get getUrl(): string {
    return this.ownerUrl;
  }

  get userName(): string {
    const userSettings = this.preferences.userSettings;
    return String(userSettings.first_name
      ? (userSettings.first_name + ' ' + userSettings.last_name)
      : userSettings.email);
  }

  /**
   * @description
   * Method that returns the user avatar
   */
  get userAvatar(): string | undefined {
    return this.preferences.userSettings.avatar;
  }

  /**
   * @description
   * Method that returns the customer contract status class
   */
  get customerContractStatusClass(): string {
    const customerData = this.preferences.userCustomer();
    if (!customerData) return '';
    if (!this.isActive) return '';
    return this.warningsClassControl[customerData.contract_status];
  }

  /**
   * @description
   * Method that return the url of logotype
   */
  get avatarPicture(): string {
    return this.userAvatar ?? '';
  }


  /**
   * @description
   * Method that responds to the OnInit lifecycle
   */
  override ngOnInit() {
    super.ngOnInit();
  }


  /**
   * @description
   * Method that response to click on Overlay
   */
  onClickOverlay() {
    this.showOverlay = false;
  }

  /**
   * @description
   * Method that response to click on user avatar
   */
  onClickUserAvatar() {
    this.showOverlay = true;
  }

  /**
   * @description
   * Returns the string it receives or an empty string if nothing is received.
   * @param value
   */
  parseString(value: string | null | undefined): string {
    return <string>value
  }

}
