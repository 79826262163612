import {Injectable, signal} from '@angular/core';
import {map, Observable, share} from 'rxjs';
import {MkadBaseService, URLS_SERVICE} from '@shared/services';
import {ZbUserProfileInterface} from '@zibanu/auth';
import {
  AppSettingsRequestInterface,
  LanguageDataResponseDtoInterface,
  MkadResumeDataListInterface,
  TimezoneDataInterface, UserCustomerDataInterface,
  UserProfileInterface,
  UserProfileRequestInterface,
} from '../../interface';
import {HttpResponse} from '@angular/common/http';
import {NbThemeService} from '@nebular/theme';
import {UserLevelEnum} from '@zibanu/auth/lib/enums/user-level.enum';
import {AllowedLanguagesEnum, MkadThemeValueEnum} from '@shared/enums';

@Injectable()
export class PreferencesService extends MkadBaseService {
  readonly coreEndpoints = URLS_SERVICE.core;
  readonly authEndpoints = URLS_SERVICE.auth;
  readonly coreBase = this.coreEndpoints.base;
  readonly authBase = this.authEndpoints.base;
  centerMap = signal<[number, number]>([0, 0]);
  private readonly appSettingApi = this.authBase + this.authEndpoints.settings.base + this.authEndpoints.settings.update;
  private readonly profileUpdateApi = this.authBase + this.authEndpoints.profile.get + this.authEndpoints.profile.update;
  private readonly profileDataApi = this.authBase + this.authEndpoints.profile.base + this.authEndpoints.profile.get;


  private _username?: string;
  get username(): string | undefined {
    return this._username;
  }

  set username(value: string | undefined) {
    this._username = value;
  }

  private _last_login?: string;
  get lastLogin(): string | undefined {
    return this._last_login;
  }

  set lastLogin(value: string | undefined) {
    this._last_login = value;
  }

  private _level= UserLevelEnum.Guest;
  get level(): number {
    return this._level;
  }

  set level(value: number) {
    this._level = value;
  }


  private _avatar: string | undefined;
  get avatar(): string | undefined {
    return this._avatar;
  }

  set avatar(value: string | undefined) {
    this._avatar = value;
  }

  private _appSettings?: AppSettingsRequestInterface;
  get appSettings(): AppSettingsRequestInterface | undefined{
    return this._appSettings;
  }

  set appSettings(value: AppSettingsRequestInterface | undefined) {
    this._appSettings = value;
  }

  private _userSettings: UserProfileRequestInterface | object = {};
  get userSettings(): UserProfileRequestInterface {
    return this._userSettings;
  }

  set userSettings(value: UserProfileRequestInterface) {
    this._userSettings = {...value};
    this.avatar = undefined;
    if (value.app_settings) this.appSettings = <AppSettingsRequestInterface>value.app_settings;
    if (value.avatar) this.avatar = value.avatar;
  }

  readonly userCustomer = signal<UserCustomerDataInterface | null>(null)

  /**
   * Constructor class
   */
  constructor(private readonly theme: NbThemeService) {
    super();
  }

  /**
   * @description
   * Method that queries the list of languages
   */
  getLanguageData(): Observable<MkadResumeDataListInterface<string>[]> {
    return this.http.post<LanguageDataResponseDtoInterface>(this.coreBase + this.coreEndpoints.lang).pipe(
      map((response): MkadResumeDataListInterface<string>[] => {
        const {languages} = response;
        if (!languages) return [];
        const keyList: string[] = Object.keys(languages);
        return keyList.map((keyValue: string): MkadResumeDataListInterface<string> => {
          return {
            id: keyValue[0] + keyValue[1],
            name: languages[keyValue]
          };
        });
      })
    );
  }

  /**
   * @description
   * Method that queries the list of timezones
   */
  getTimeZoneData(): Observable<string[]> {
    return this.http.post<TimezoneDataInterface>(this.coreBase + this.coreEndpoints.timezone).pipe(
      map((response: TimezoneDataInterface) => {
        return response.timezones;
      })
    );
  }

  /**
   * @description
   * Method that return the user profile data
   */
  getProfileData(): Observable<UserProfileInterface | undefined> {
    return this.http.post<UserProfileInterface>(this.profileDataApi).pipe(share());
  }

  /**
   * @description
   * Method that set the username of the user
   */
  changeUserConfig() {
    if (!this.isObjectEmpty(this.userSettings)) {
      this.translate.use(<string>this.userSettings.lang?.toLowerCase()).subscribe({});
      this.theme.changeTheme(<string>this.userSettings.theme?.toLowerCase());
    }
    return undefined;
  }

  /**
   * Method that verifies whether an object is empty or not
   * @param objectName
   */
  isObjectEmpty<T = Record<string, unknown>>(objectName: T) {
    return Object.keys(objectName as any).length === 0;
  }

  /**
   * @description
   * Method that set the username of the user
   */
  changeToDefaultConfig(): void {
    this.theme.changeTheme(MkadThemeValueEnum.corporate);
    this.translate.use(AllowedLanguagesEnum.es).subscribe({});
  }

  /**
   * @description
   * Method that updates the user's profile data
   * @param profile
   */
  updateProfile(profile: UserProfileRequestInterface): Observable<HttpResponse<ZbUserProfileInterface>> {
    return this.http.post(this.profileUpdateApi, profile);
  }

  /**
   * @description
   * Method that updates the app's settings data
   * @param settings app config for payload
   */
  updateAppSetting(settings: AppSettingsRequestInterface): Observable<HttpResponse<null>> {
    return this.http.post(this.appSettingApi, settings);
  }

}
