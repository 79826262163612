import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {NbIconModule, NbThemeService, NbTooltipModule} from '@nebular/theme';
import {AsyncPipe, NgOptimizedImage} from '@angular/common';
import {RouterLink} from '@angular/router';
import {SharedModule} from '@shared/shared.module';
import {ZbBaseComponent} from '@zibanu/ui';
import { Observable } from 'rxjs';

@Component({
  selector: 'mkad-frame-footer',
  templateUrl: 'frame-footer.component.html',
  standalone: true,
  imports: [TranslateModule, NgOptimizedImage, NbTooltipModule, NbIconModule, RouterLink, SharedModule, AsyncPipe],
})
export class FrameFooterComponent extends ZbBaseComponent implements OnInit{
  @Input({required: true}) politiesUrl = '';
  @Output() clickLeftIcon = new EventEmitter();

  /**
   * @description
   * Constructor class
   * @param translate TranslateService dependency injection
   * @param theme NbThemeService dependency injection
   */
  constructor(override translate: TranslateService,
              private readonly theme: NbThemeService) {
    super(translate);
  }

  get onChangeTheme(): Observable<{ name: string }> {
    return this.theme.onThemeChange();
  }

  /**
   * @description
   * Method that responds to the OnInit lifecycle
   */
  override ngOnInit() {
    super.ngOnInit();
  }

  /**
   * @description
   * Method that return the logo url
   */
  protected logoPath(theme: string): string {
    return `assets/image/meerkad_${theme !== 'corporate' ? 'dark' : 'light'}.webp`;
  }


}
