/*
 * Developed by CQ Inversiones SAS. Copyright ©. 2019 - 2024. All rights reserved.
 * Desarrollado por CQ Inversiones SAS. Copyright ©. 2019 - 2024. Todos los derechos reservados.
 */
import {
  CustomerResumeInterface,
  DialogContextInterface, EnumOptionDataInterface, IconListInterface,
  MkadSummaryListInterface, SensorTypeEnum
} from '@shared/interface';
import {SummaryParamInterface} from '@shared/interface/settings/system/parameters/parameters.interface';
import {PortTypeEnum} from '@shared/interface/settings/system/device-model';

export enum CustomLevelEnum {
  sensor,
  model,
}

export enum CoreMkadStatusEnum {
  success,
  alert,
  warning,
  error,
}

export enum LimitLevelEnum {
  low,
  high
}

export const DEFAULT_FORMULA_FS = '{FS}';
export const DEFAULT_FORMULA_FM = '{FM}';

export interface MkadCustomerDeviceInterface {
  customer_device_id: number,
}

/**
 * Interface of customer device list
 */
export interface CustomerDeviceListInterface {
  id: number,
  customer: number,
  serial: string,
  model_name: string,
  location_name: string | null,
  enabled: boolean
}

export interface MkadVariableDataDtoInterface {
  id: number,
  model_port_id: number,
  virtual: boolean
}

/**
 * Interface of customer device full data detail
 */
export interface CustomerDeviceDetailInterface extends CustomerDeviceDto {
  serial: string;
  model_id: number;
  model_name: string;
  device: number;
  location_name: string |null;
  customer: number;
}

/**
 * Interface data transfer object of customer device full data
 */
export interface CustomerDeviceDto extends CustomerDeviceDataBasicInterface {
  groups: number[];
  customer_device_parameters: CustomerDeviceParametersInterface[];
  customer_device_ports: CustomerDevicePortsGetterInterface[];
}

/**
 * Interface data transfer object of customer device full data
 */
export interface CustomerDeviceUpdateDto extends CustomerDeviceDataBasicInterface {
  groups: number[];
  customer_device_parameters: CustomerDeviceParametersInterface[];
  customer_device_ports: CustomerDevicePortsDtoInterface[];
}

/**
 * Interface of customer device basic data form
 */
export interface CustomerDeviceDataBasicInterface {
  id: number;
  contract: number | null;
  is_public: boolean;
  enabled: boolean;
  location: number | null;
}

/**
 * Interface of customer device basic data form
 */
export interface CustomerDeviceParametersFormInterface extends Omit<DeviceParametersListInterface, 'id'> {
  id?: number,
}

export interface DeviceParametersListInterface extends CustomerDeviceParametersInterface {
  name: string,
}

/**
 * Interface of customer device basic data form
 */
export interface CustomerDeviceParametersInterface {
  id?: number,
  default_parameter: number,
  value: string,
}

export interface CustomerDevicePortsGetterInterface extends Omit<CustomerDevicePortsListInterface, 'customer_device_vars'>{
  customer_device_vars: CustomerDeviceVarGetterInterface[];
}

export interface CustomerDevicePortsListInterface extends Omit<CustomerDevicePortsDtoInterface, 'customer_device_vars'>{
  device_model_port_label: string;
  device_model_port_type: PortTypeEnum;
  customer_device_vars: SensorCustomerVarInterface[];
}

export interface CustomerDevicePortsDtoInterface {
  id: number | null;
  device_model_port: number;
  sensor: number | null; // update to number | null, using -1 momentarily
  enabled: boolean;
  customer_device_vars: CustomerDeviceVariableInterface[];
}

export interface SensorCustomVarsDtoInterface {
  model_port_id: number,
  customer_device_id: number
}

export interface SensorCustomVarDataInterface {
  sensors: SensorCustomerListInterface[];
  virtual_vars: VirtualVarDataInterface[];
}

export interface CustomerDevicePortSummaryInterface extends Omit<SensorCustomerListInterface, 'selected' | 'sensor_vars' | 'type'> {
  type: PortTypeEnum;
  enabled: boolean;
}

export interface SensorCustomerListInterface {
  id: number;
  description: string;
  selected: boolean;
  type: SensorTypeEnum;
  sensor_vars: SensorCustomerVarInterface[];
}

export interface SensorCustomerVarInterface extends Omit<CustomerDeviceVariableInterface, 'sensor_var' | 'virtual_var'> {
  customer_var_id: number | null; // id de la customización
  in_trigger: boolean;
  in_virtual: boolean;
  custom_level: CustomLevelEnum;
  customized: boolean;
}

export interface CustomerDeviceVarGetterInterface extends Omit<CustomerDeviceVariableInterface, 'id'> {
  id: number;
  in_trigger: boolean | null;
}

export interface VirtualVarDataInterface extends Omit<CustomerDeviceVariableInterface, 'sensor_var' | 'virtual_var'>{
  custom_level: CustomLevelEnum;
  customized: boolean;
}

export interface CustomerDeviceVariableInterface {
  id: number | null;
  name: string;
  short_name: string;
  formula: string;
  show: boolean;
  chart: boolean;
  enabled: boolean;
  icon: number;
  max_value: number | null;
  min_value: number | null;
  sensor_var: number | null;
  virtual_var: number | null;
  customer_device_var_limits: CustomerDeviceLimitInterface[];
}

export interface CustomerDeviceLimitInterface {
  id: number | null;
  alert_level: LimitLevelEnum;
  alert_status: CoreMkadStatusEnum;
  value: number | null;
  notify_to_customer: boolean;
  notify_to_support: boolean;
  enabled: boolean;
  alert_level_label?: string;
  alert_status_label?: string;
  in_trigger?: boolean;
}

export interface CustomerDeviceVarUtils {
  custom_level: EnumOptionDataInterface[];
}

export interface CustomerDeviceLimitUtils {
  alert_level: EnumOptionDataInterface[];
  alert_status: EnumOptionDataInterface[];
}

export interface CustomerDeviceUtilsInterface {
  customer_device_var_limits: CustomerDeviceLimitUtils;
  custom_vars: CustomerDeviceVarUtils[];
}

export interface CustomerDeviceDialogContextInterface extends DialogContextInterface {
  customerDevice: CustomerDeviceDetailInterface;
}

export interface GroupsDialogContextInterface extends Pick<DialogContextInterface, 'isEditing'> {
  groupAvailableList: MkadSummaryListInterface[];
  deviceLocation: string;
  customer: CustomerResumeInterface;
  groupsSelected: number[];
}

export interface ParamDialogContextInterface extends Pick<DialogContextInterface, 'customer'> {
  param?: CustomerDeviceParametersInterface;
  deviceLocation: string;
  paramsAvailable: SummaryParamInterface[];
  paramsCustomized: CustomerDeviceParametersInterface[];
}

export interface ParamValueDialogContextInterface extends Pick<DialogContextInterface, 'isEditing'> {
  param?: CustomerDeviceParametersInterface;
  paramsAvailable: MkadSummaryListInterface[];
}

export interface PortsDialogContextInterface extends DialogContextInterface {
  deviceLocation: string;
  customisedPorts: CustomerDevicePortsListInterface[];
  allAvailablePorts: MkadSummaryListInterface[];
  customisedDevice?: CustomerDeviceDetailInterface;
}

export interface PortsValueDialogContextInterface extends DialogContextInterface {
  portSelected: CustomerDevicePortsListInterface;
  customerDevice?: CustomerDeviceDetailInterface;
  deviceLocation: string;
}

export interface VariableDialogContextInterface extends Omit<DialogContextInterface, 'customer'> {
  variable: SensorCustomerVarInterface;
  iconsList?: IconListInterface[];
  statusList: EnumOptionDataInterface[];
  levelList: EnumOptionDataInterface[];
  deviceLocation: string;
  disableStatusSwitch: boolean;
  sensorDescription: string;
  portType: PortTypeEnum;
}

export interface LimitDialogContextInterface extends Pick<DialogContextInterface, 'isEditing'> {
  statusList: EnumOptionDataInterface[];
  levelList: EnumOptionDataInterface[];
  variableData: SensorCustomerVarInterface;
  locationName: string;
}

export interface LimitDataDialogContextInterface extends Omit<LimitDialogContextInterface, 'variableData'> {
  limitData: CustomerDeviceLimitInterface | null;
  limitsList: CustomerDeviceLimitInterface[];
  variableData?: SensorCustomerVarInterface;
}
