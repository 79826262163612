/*
 * Project:      mkad-frontend
 * Developed by: Juan David Pelaez Cumbe
 * Date:         23/02/24 - 13:31
 * Module name:  app-settings-form.constants
 * File name:    mkad-app-settings-form.constants.ts
 * IDE:          WebStorm
 */

import {MkadResumeDataListInterface} from '@shared/interface';
import {FormatTypeExportEnum, ManageNullSettingEnum} from '@shared/enums';

/**
 * @description
 * List with the number of decimals available for display in reports and/or graphs
 */
const sixty: number = 60;
export const DECIMAL_OPTIONS: number[] = [0, 1, 2, 3, 4, 5, 6];
export const REFRESH_OPTIONS: number[] = [3, 5, 10, 15, 20, 30, sixty];

/**
 * @description
 * List with the extensions allowed by the system to export reports.
 */
export const EXPORT_TYPE_OPTIONS: MkadResumeDataListInterface<number>[] = [
  {
    id: FormatTypeExportEnum.XLS,
    name: 'XLS'
  },
  {
    id: FormatTypeExportEnum.XLSX,
    name: 'XLSX'
  },
  {
    id: FormatTypeExportEnum.CSV,
    name: 'CSV'
  },
  {
    id: FormatTypeExportEnum.TXT,
    name: 'TXT'
  }
];

/**
 * @description
 * List of options allowed by the system for handling null values
 */
export const MANAGE_DATA_NULL_OPTIONS: MkadResumeDataListInterface<number>[] = [
  {
    id: ManageNullSettingEnum.Discard,
    name: 'app.shared.appSettings.field.nullManage.options.discard'
  },
  {
    id: ManageNullSettingEnum.Average,
    name: 'app.shared.appSettings.field.nullManage.options.average'
  },
  {
    id: ManageNullSettingEnum.PreviousValue,
    name: 'app.shared.appSettings.field.nullManage.options.previousValue'
  },
  {
    id: ManageNullSettingEnum.NextValue,
    name: 'app.shared.appSettings.field.nullManage.options.nextValue'
  }
];

